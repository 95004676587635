<template>
	<div class="download-container">
		<div class="download">
			<div class="download-revit">
				<p class="title">{{$t('common.otherDownloads')}}</p>
				<p v-for="(product,index) in Product1" :key="index"><a href="javascript:void(0)" @click="download(product)">{{product.name}}</a></p>
			</div>
			<div class="download-video">
				<p class="title">{{$t('common.softwareDownloading')}}</p>
				<p v-for="(product,index) in Product4" :key="index"><a href="javascript:void(0)" @click="download(product)">{{product.name}}</a></p>
			</div>
		</div>
	</div>
</template>

<script>
import jsonwebtoken from 'jsonwebtoken'
export default {
  metaInfo: {
    title: 'emData 下载',
    meta:
			[
			  {
			    name: 'emData 插件下载',
			    content: 'emData 插件有哪些'
			  },
			  {
			    name: '鹦鹉塔插件下载',
			    content: '鹦鹉塔插件下载有哪些'
			  },
			  {
			    name: 'emData 视频教学',
			    content: 'emData系统视频教学'
			  }
			],
    link:
			[
			  {
			    rel: 'emData 下载',
			    href: 'https://www.emdataer.com/download'
			  }
			]
  },
  name: 'download',
  data () {
    return {
      tableData_Product: [],
      Product4: [],
      Product1: []
    }
  },
  created () {
    this.getProducts()
  },
  methods: {
    getProducts () {
      this.$axios.get(`/api/product/getProducts`).then(res => {
        console.log(res)
        this.tableData_Product = res
        this.Product4 = res.filter(v => {
          return v.type == 4
        })
        this.Product1 = res.filter(v => {
          return v.type == 1
        })
      })
    },
    async download (product) {
      var date = 0
      if (localStorage.getItem('eleToken')) {
        var token = localStorage.getItem('eleToken')
        token = token.split(' ')
        date = new Date(jsonwebtoken.decode(token[1]).exp) * 1000
      }
      var dateNow = new Date().getTime()
      if (dateNow > date) {
        this.$confirm('用户需要登录之后才能下载对应产品，是否登录？', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          this.$router.push({
            name: 'login',
            query: {
              type: 'download'
            }
          })
        }).catch(() => {
          this.$message({
            type: 'info',
            message: '已取消登录'
          })
        })
      } else {
        // this.$message('文件准备中，请稍等');
        this.$notify.info({
          title: '文件准备中...',
          message: '请稍等...',
          duration: 2000
        })
        // 这里写下载相关代码
				    location.href = `/api/product/downproduct?filePath=${product.attachmentAddress}`
      }
      // let res = await this.$axios.post(`/api/user/downproduct?filePath=${product.attachmentAddress}`, {
      // 		headers: {
      // 			'Content-Type': 'application/json; application/octet-stream'
      // 		}
      // 	}, {
      // 		responseType: 'blob'
      // 	})
      // 	.then(res => {
      // 		console.log(res)
      // 		if (res.Message == "无登录信息或登录信息已失效，请重新登录。") {

      // 			this.$confirm('此操作需要登录才可进行，是否前往登录？', '提示', {
      // 				confirmButtonText: '确定',
      // 				cancelButtonText: '取消',
      // 				type: 'warning'
      // 			}).then(() => {
      // 				// this.$message({
      // 				// 	type: 'success',
      // 				// 	message: '删除成功!'
      // 				// });
      // 				this.$router.push({
      // 					name: 'login',
      // 					query: {
      // 						type: 'download'
      // 					}
      // 				})
      // 			}).catch(() => {
      // 				this.$message({
      // 					type: 'info',
      // 					message: '已取消登录'
      // 				});
      // 			});
      // 		} else {
      // 			// let type = 'application/octet-stream'
      // 			// const href = res
      // 			const href = window.URL.createObjectURL(new Blob([res]))
      // 			const a = document.createElement('a')
      // 			a.style.display = 'none'
      // 			a.href = href // 指定下载链接
      // 			a.download = product.name // 指定下载文件名
      // 			a.click() //触发下载
      // 			URL.revokeObjectURL(a.href) // 释放URL对象
      // 			this.$message({
      // 				showClose: true,
      // 				message: '下载完成',
      // 				type: 'success'
      // 			});
      // 		}
      // 	})
    }
  }
}
</script>

<style lang="less" scoped>
	.download-container {
		// height: calc(100vh - 74px);
		height: 863px;

		.el-button--primary {
			background-color: #475065;
			color: #fff;
			border-color: #475065;
		}
		.download{
			display: flex;
			justify-content: space-evenly;
			align-items: center;
			// background-color: #000000;
			width: 1200px;
			height: 100%;
			margin: auto;
			.download-revit,.download-video{
				width: 400px;
				height: 600px;
				overflow: auto;
				// background-color: #fff;
				border: 3px solid #7f7f7f;
				p{
					text-align: left;
					padding: 10px;
				}
				p > a{
					color:#2c3e50;
				}
				// p:nth-child(2){
				// 	text-align: center;
				// 	// font-size: 28px;
				// }
				// p:nth-child(3){
				// 	text-align: center;
				// 	// padding-left: 50px;
				// 	padding-top: 0;
				// 	font-size: 20px;
				// }
				.title{
					// margin-top: 60px;
					margin:10px;
					font-size: 35px;
				}
				.download-btn{
					margin-top: 100px;
				}
			}
			.download-revit{

			}
			.download-video{
				p:nth-child(2){
					// text-align: center;
					// font-size: 22px;
				}
			}
		}
	}
</style>
